import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import BgImage from 'Components/common/BgImage'
import productImage from 'images/home/mobile-section-front@2x.png'
import { Col, Container, Row } from 'reactstrap'
import GooglePlayBadge from 'Components/common/GooglePlayBadge'
import AppStoreBadge from 'Components/common/AppStoreBadge'
import FormattedHtmlMessage from 'Components/common/FormattedHtmlMessage'
import Parser from 'html-react-parser'

interface IProps {
  darkBg?: boolean
  mobileApps?: {
    [key: string]: string
  }
}

const MobileApps: React.FC<IProps> = ({ darkBg, mobileApps }) => (
  <StaticQuery
    query={graphql`
      query mobileAppsBackgroundImageQuery {
        bgImage: file(relativePath: { eq: "home/mobile-section-bg@3x.png" }) {
          childImageSharp {
            fixed(width: 2560, quality: 85) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <section>
          <BgImage imgFixed={data.bgImage.childImageSharp.fixed}>
            <Container className="py-8">
              <Row>
                <Col xs="12" lg="6">
                  <div className="text-center text-lg-right">
                    <h1 className="mb-4">
                      <span className="mobile-apps-header">
                        {mobileApps ? (
                          Parser(mobileApps.header)
                        ) : (
                          <FormattedHtmlMessage id="mobileApps.header" />
                        )}
                      </span>
                    </h1>
                    <Row>
                      <div className="col-md-1 col-lg-3 d-none d-md-block" />
                      <p className="mb-4 col-lg-9 col-md-10 col-12">
                        {mobileApps ? (
                          mobileApps.message
                        ) : (
                          <FormattedHtmlMessage id="mobileApps.message" />
                        )}
                      </p>
                    </Row>
                    <div className=" mb-4">
                      <GooglePlayBadge />
                      <AppStoreBadge />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </BgImage>
          <Container>
            <Row>
              <Col md="8" lg="6">
                <div className="container position-relative">
                  <img
                    alt="Fonn product promo"
                    className="mobile-apps-product-image d-none d-md-block"
                    src={productImage}
                  />
                </div>
              </Col>
            </Row>
          </Container>
          <div
            className={` d-none d-md-block ${
              darkBg
                ? 'mobile-apps-background-bg-dark'
                : 'mobile-apps-background'
            }`}
          />
        </section>
      )
    }}
  />
)

export default MobileApps
