import React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import { Button, Col } from 'reactstrap'
import imgProductShowcase from 'images/home/intro-device-mockups@2x.png'
import BgImage from 'Components/common/BgImage.tsx'
import Parser from 'html-react-parser'

interface IProps {
  intro: {
    [key: string]: string
  }
  location: any
}

const createRequestDemoLink = (location: any) => {
  switch (location.pathname.split('/')[1]) {
    case 'en':
      return '/en/request-demo'
    case 'pl':
      return '/pl/popros-o-demo'
    case '':
      return '/bestill-en-demo'
    default:
      return '/bestill-en-demo'
  }
}

const createTryForFreeLink = (location: any) => {
  switch (location.pathname.split('/')[1]) {
    case 'en':
      return '/en/try-for-free'
    case 'pl':
      return '/pl/wyprobuj-za-darmo'
    case '':
      return '/prov-gratis'
    default:
      return '/prov-gratis'
  }
}

const Intro: React.FC<IProps> = ({ intro, location }) => (
  <StaticQuery
    query={graphql`
      query imageQuery {
        bgImage: file(relativePath: { eq: "home/Fonn_04.jpg" }) {
          childImageSharp {
            fixed(width: 2560, quality: 85) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <section>
        <BgImage
          className="intro-background"
          imgFixed={data.bgImage.childImageSharp.fixed}
        >
          <div className="container py-8">
            <h1 className="display-4 mb-4">{Parser(intro.title)}</h1>
            <p className="lead mb-4">{Parser(intro.subtitle)}</p>
            <Button
              className="mr-2 intro-try-for-free"
              tag={Link}
              to={createTryForFreeLink(location)}
            >
              {intro.trialButton}
            </Button>
            <Button
              tag={Link}
              to={createRequestDemoLink(location)}
              className="less-screaming-green"
            >
              {intro.contactButton}
            </Button>
          </div>
        </BgImage>
        <div className="container position-relative">
          <img
            src={imgProductShowcase}
            className="intro-product-showcase"
            alt="Fonn product showcase"
          />
        </div>
        <div className="container intro-enticement-container p-0">
          <Col
            xs={12}
            lg={6}
            md={5}
            style={{
              height: '110%',
              position: 'relative',
            }}
          >
            <h2
              className="intro-enticement pb-0 mb-0 pb-lg-5 mb-lg-5"
              style={{ bottom: 0 }}
            >
              {Parser(intro.enticement)}
            </h2>
          </Col>
        </div>
      </section>
    )}
  />
)

export default Intro
