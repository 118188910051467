import React from 'react'

const GooglePlayBadge: React.FC = () => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href="https://play.google.com/store/apps/details?id=no.byggemappen"
  >
    <img
      style={{ height: '60px' }}
      alt="Get it on Google Play"
      src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
    />
  </a>
)

export default GooglePlayBadge
