import React from 'react'

const AppStoreBadge: React.FC = () => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href="https://itunes.apple.com/us/app/byggemappen/id1229059264"
  >
    <img
      src="https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2017-05-11&kind=iossoftware&bubble=ios_apps"
      style={{ height: '40px' }}
      alt="Get it on AppStore"
    />
  </a>
)

export default AppStoreBadge
