import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const Video = () => {
  return (
    <Container className="my-5">
      <Row>
        <Col>
          <div className="iframe-container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/PFJU5ttN9V0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Video
