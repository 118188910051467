import React from 'react'
import { Col } from 'reactstrap'
import { FormattedMessage } from 'react-intl'

interface IProps {
  icon: any
  header: string
  description: string
  xs?: number | object
  sm?: number | object
  md?: number | object
  lg?: number | object
  xl?: number | object
}

const Argument: React.FC<IProps> = ({
  icon,
  header,
  description,
  xs = 12,
  sm = 6,
  md = 4,
  lg = 4,
  xl = 4,
}) => (
  <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} className="d-flex mb-4 justify-content-center">
    <div className="mr-2">
      <img src={icon} />
    </div>
    <div>
      <h4>
        <FormattedMessage id={header} defaultMessage={header} />
      </h4>
      <p>
        <FormattedMessage id={description} defaultMessage={description} />
      </p>
    </div>
  </Col>
)

export default Argument
