import React from 'react'
import { Container, Row } from 'reactstrap'
import Argument from 'Components/home/Argument'

interface IProps {
  salesArgs: any
}

// ASSETS:
import Forms from 'Images/product/statement/cr.svg'
import DirectCommunication from 'Images/product/statement/convers.svg'
import UserFriendly from 'Images/home/userFriendly.svg'
import PayPerProject from 'Images/home/payPerProject.svg'
import Share from 'Images/home/shareDocs.svg'
import Notifications from 'Images/home/notifications.svg'
import Warranty from 'Images/home/warrantyClaims.svg'
import RoleManagement from 'Images/home/roleManagement.svg'

const SalesArguments: React.FC<IProps> = ({ salesArgs }) => (
  <Container className="pt-6">
    <Row>
      <Argument
        icon={UserFriendly}
        header={salesArgs._1.header}
        description={salesArgs._1.description}
      />
      <Argument
        icon={PayPerProject}
        header={salesArgs._2.header}
        description={salesArgs._2.description}
      />
      <Argument
        icon={DirectCommunication}
        header={salesArgs._3.header}
        description={salesArgs._3.description}
      />
      <Argument
        icon={Share}
        header={salesArgs._4.header}
        description={salesArgs._4.description}
      />
      <Argument
        icon={Notifications}
        header={salesArgs._5.header}
        description={salesArgs._5.description}
      />
      <Argument
        icon={Warranty}
        header={salesArgs._6.header}
        description={salesArgs._6.description}
      />
      <Argument
        md={{ size: 4, offset: 2 }}
        icon={RoleManagement}
        header={salesArgs._7.header}
        description={salesArgs._7.description}
      />
      <Argument
        icon={Forms}
        header={salesArgs._8.header}
        description={salesArgs._8.description}
      />
    </Row>
  </Container>
)

export default SalesArguments
