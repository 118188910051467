import React from 'react'
import { Container, Row } from 'reactstrap'

interface IProps {
  messageId: string
  clientNameId: string
  clientPositionId: string
  companyImageUrl: string
}

const ClientTestimonial: React.FC<IProps> = ({
  messageId,
  clientNameId,
  clientPositionId,
  companyImageUrl,
}) => (
  <section className="client-testimonial-background">
    <Container>
      <Row>
        <div className="col-md-2 col-lg-3 d-none d-sm-block" />
        <div className="text-center py-7 px-3 col-12 col-md-8 col-lg-6">
          <h2 className="lead pb-3">
            <em>{messageId}</em>
          </h2>
          <div className="client-testimonial-divider">
            <p>
              <b>{clientNameId}</b>
              <br />
              {clientPositionId}
            </p>
            <img
              style={{ height: 20 }}
              src={companyImageUrl}
              alt="fonn client logo"
            />
          </div>
        </div>
      </Row>
    </Container>
  </section>
)

export default ClientTestimonial
